import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Product from './Product'
import Products from './Products'

//IMPORT CSS
import './ProductRow.css'

export default function ProductRow(props) {

    const products = props.products.map(element =>
        <Col key={element.id} className="col-product-row">
            <Product product={element}/>
        </Col>
        )

        
    return (
        <Row>
            {products}
        </Row>
    )
}
