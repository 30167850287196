import React from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'

// IMPORT CSS
import './Product.css'

export default function Product(props) {

    const { product } = props

    let count = 0;

    const slider =
        <a href={product.link}>
            <Carousel controls={false} indicators={false} interval={2000} pause="hover">
                {product.photos.map(p => (
                    <Carousel.Item key={count++}>
                        <div className="img-container">
                            <img src={p} />
                        </div>
                    </Carousel.Item>)
                )}
            </Carousel>
        </a>

    return (
        <div className="product-background">
            <div className="product-container">
                <p className="product-title">{product.title}</p>
                <div className="product-content">
                    {slider}
                </div>
            </div>
        </div>
    )
}
