import React from 'react'
import { Row } from 'react-bootstrap'


//#region IMPORT IMG

import rovatti1 from '../../img/rovatti/1.png'
import rovatti2 from '../../img/rovatti/2.png'
import rovatti3 from '../../img/rovatti/3.png'
import rovatti4 from '../../img/rovatti/4.png'

import gf1 from '../../img/gf/1.jpg'
import gf2 from '../../img/gf/2.jpg'
import gf3 from '../../img/gf/3.jpg'
import gf4 from '../../img/gf/4.jpg'
import gf5 from '../../img/gf/5.jpg'
import gf6 from '../../img/gf/6.jpg'
import gf7 from '../../img/gf/7.jpg'
import gf8 from '../../img/gf/8.jpg'
import gf9 from '../../img/gf/9.jpg'

import lucaniaTubi1 from '../../img/lucania-tubi/1.jpg'
import lucaniaTubi2 from '../../img/lucania-tubi/2.jpg'
import lucaniaTubi3 from '../../img/lucania-tubi/3.jpg'
import lucaniaTubi4 from '../../img/lucania-tubi/4.jpg'
import lucaniaTubi5 from '../../img/lucania-tubi/5.jpg'
import lucaniaTubi6 from '../../img/lucania-tubi/6.jpg'
import lucaniaTubi7 from '../../img/lucania-tubi/7.jpg'
import lucaniaTubi8 from '../../img/lucania-tubi/8.jpg'

import lucaniaSerbatoi1 from '../../img/lucania-serbatoi/1.jpg'
import lucaniaSerbatoi2 from '../../img/lucania-serbatoi/2.jpg'
import lucaniaSerbatoi3 from '../../img/lucania-serbatoi/3.jpg'
import lucaniaSerbatoi4 from '../../img/lucania-serbatoi/4.jpg'
import lucaniaSerbatoi5 from '../../img/lucania-serbatoi/5.jpg'

import glongDomestiche1 from '../../img/glong-domestiche/1.jpg'
import glongDomestiche2 from '../../img/glong-domestiche/2.jpg'
import glongDomestiche3 from '../../img/glong-domestiche/3.jpg'
import glongDomestiche4 from '../../img/glong-domestiche/4.jpg'
import glongDomestiche5 from '../../img/glong-domestiche/5.jpg'
import glongDomestiche6 from '../../img/glong-domestiche/6.jpg'
import glongDomestiche7 from '../../img/glong-domestiche/7.jpg'
import glongDomestiche8 from '../../img/glong-domestiche/8.jpg'

import glongPiscine1 from '../../img/glong-piscine/1.jpg'
import glongPiscine2 from '../../img/glong-piscine/2.jpg'
import glongPiscine3 from '../../img/glong-piscine/3.jpg'
import glongPiscine4 from '../../img/glong-piscine/4.jpg'




//#endregion  

// IMPORT CSS
import './Products.css'
import ProductRow from './ProductRow'

export default function Products() {

    const rovatti = [rovatti1, rovatti2, rovatti3, rovatti4]
    const gf = [gf1, gf2, gf3, gf4, gf5, gf6, gf7, gf8, gf9]
    const lucaniaTubi = [lucaniaTubi1, lucaniaTubi2, lucaniaTubi3, lucaniaTubi4, lucaniaTubi5, lucaniaTubi6, lucaniaTubi7, lucaniaTubi8]
    const lucaniaSerbatoi = [lucaniaSerbatoi1, lucaniaSerbatoi2, lucaniaSerbatoi3, lucaniaSerbatoi4, lucaniaSerbatoi5]
    const glongDomestiche = [glongDomestiche1, glongDomestiche2, glongDomestiche3, glongDomestiche4, glongDomestiche5, glongDomestiche6, glongDomestiche7, glongDomestiche8]
    const glongPiscine = [glongPiscine1, glongPiscine2, glongPiscine3, glongPiscine4]


    return (
        <div id="prodotti" className="products-container">
            <p className="products-title">I miei prodotti</p>
            <ProductRow
                products={[{ id: "Rovatti", title: "Pompe acquedottistica", photos: rovatti, link: "https://www.rovatti.it/prodotti"}, {id: "gf", title: "Raccordi e accessori", photos:  gf, link: "https://www.gfps.com/appgate/ecat/common_flow/10005T/IT/it/index.html"}]}
            />
            <ProductRow
                products={[{ id: "lucaniaTubi", title: "Tubi e accessori", photos: lucaniaTubi, link: "https://tubi.net/prodotti-polietilene-polipropilene-pvc-system-group/tubi-polietilene-polipropilene-pvc/"}, {id: "lucaniaSerbatoi", title: "Serbatoi e depurazione", photos:  lucaniaSerbatoi, link: "https://tubi.net/prodotti-polietilene-polipropilene-pvc-system-group/serbatoi-acqua-e-depurazione/"}]}
            />
            <ProductRow
                products={[{ id: "glongDomestiche", title: "Pompe uso domestico", photos: glongDomestiche, link: "http://www.glong-motor.com/product/7/"}, {id: "glongPiscine", title: "Pompe per piscine", photos:  glongPiscine, link: "http://www.glong-motor.com/product/21/"}]}
            />

        </div>
    )
}
