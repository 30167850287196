import React, {useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'


// IMPORT CSS
import './Company.css'


export default function Company(props) {

    const [isOpen, setIsOpen] = useState(false)
    

    // MEDIA QUERY
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })

    // CREATE DESKTOP COMPONENT TO RENDER
    const leftCompany =
        <div className="company-content" style={{ background: props.color }}>
            <Col className="company-logo-container">
                <a className="company-logo" href={props.link}>
                    <img src={props.logo} />
                </a>
            </Col >
            <Col>
                <p className="company-description" style={{ color: props.textColor }}>{props.text}</p>
            </Col>
            <Col />
        </div>

    const rightCompany =
        <div className="company-content" style={{ background: props.color }}>
            <Col />
            <Col>
                <p className="company-description" style={{ color: props.textColor }}>{props.text}</p>
            </Col>
            <Col >
                <a className="company-logo" href={props.link}>
                    <img src={props.logo} />
                </a>
            </Col>
        </div>

    const desktop =
        <Row className="company-container">
            {props.alignment === "left" ? leftCompany : rightCompany}
        </Row>


    // CREATE MOBILE COMPONENT TO RENDER

    const mobile =
        <div className="mobile-company-container">
            <div className="mobile-company-content" style={{ background: props.color, height: isOpen ? "380px" : "250px"}}>
                <a className="company-logo" href={props.link}>
                    <img src={props.logo} />
                </a>
                <h3 style={{color: props.textColor}}>{props.title}</h3>
                <div className="company-button" onClick={() => setIsOpen(!isOpen)} ><p>{!isOpen ? "Scopri" : "Nascondi"}</p></div>
                {isOpen && <p className="company-description" style={{ color: props.textColor }}>{props.text}</p>}
            </div>
        </div>


    return (
        <Row className="company">
            {isMobile ? mobile : desktop}
        </Row>
    )
}
