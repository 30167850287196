import { Container } from 'react-bootstrap';
import './App.css';
import Contacts from './components/footer/Contacts';

// IMPORT COMPONENTS
import AppBar from './components/header/AppBar';
import Hero from './components/hero/Hero';
import Companies from './components/Partner/Companies';
import Products from './components/Products/Products';

// IMPORT ICON
import whatsappIcon from './img/illustration/iconmonstr-whatsapp-1-240.png'

function App() {
  return (
    <Container fluid>
      <AppBar />
      <Hero />
      <Companies />
      <Products />
      <Contacts />
      <div style={{ cursor: "pointer", boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)", position: "fixed", right: "20px", bottom: "20px", backgroundColor: "#25D366", borderRadius: "100%", height: "70px", width: "70px", display: "flex", justifyItems: "center", alignItems: "center" }}>
        <a href="https://wa.me/393917675500" class="float" target="_blank"><img style={{ width: "100%", height: "100%", padding: "15px" }} src={whatsappIcon} /></a>
      </div>

    </Container>

  );
}

export default App;
