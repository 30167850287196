import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-scroll'

// IMPORT CSS
import './AppBar.css'

// IMPORT IMG
import backgroundHeader from '../../img/illustration/background-header.svg'

// IMPORT COMPONENT
import Logo from '../logo/Logo'

export default function AppBar() {

    const [width, setWidth] = useState(window.innerWidth)

    function updateWindowDimensions(){
        setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWindowDimensions);

    //#region Create element to render

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-width: 1023px)'
    })

    const [active, setActive] = useState(false)

    function handleNav() {
        setActive(active ? false : true)
    }

    const mobileNav = <Col className="header-nav">
        <button onClick={handleNav} >☰</button>
        <nav style={active ? { transform: "translateX(0%)" } : { transform: "translateX(100%)" } }>
            <ul>
                <Link to="aziende" smooth={true}><li>Aziende</li></Link>
                <Link to="prodotti" smooth={true}><li>Prodotti</li></Link>
                <Link to="contatti" smooth={true}><li>Contatti</li></Link>
            </ul>
        </nav>
    </Col>

    const desktopNav = <Col className="header-nav">
        <nav>
            <img src={backgroundHeader}
                style={{ position: "absolute", top: 0, right: 0, zIndex: -1 }} />
            <Link to="aziende" smooth={true}><li>Aziende</li></Link>
            <Link to="prodotti" smooth={true}><li>Prodotti</li></Link>
            <Link to="contatti" smooth={true}><li>Contatti</li></Link>
        </nav>
    </Col>

    //#endregion

    return (
        <Row className="header">
            <Col className="header-logo">
                <div>
                    <Logo />
                </div>
            </Col>
            {isTabletOrMobileDevice && mobileNav}
            {isDesktopOrLaptop && desktopNav}
        </Row>
    )
}

const navActive = { transform: "translateX(0%)" }
const navNotActive = { transform: "translateX(100%)" }
const buttonActive = { zIndex: 3 }
