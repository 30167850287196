import React from 'react'
import { Col, Row } from 'react-bootstrap'

// IMPORT IMG
import background from '../../img/illustration/contact.jpg'
import Logo from '../logo/Logo'

// IMPORT CSS
import './Contacts.css'

export default function Contacts() {
    return (
        <Row id="contatti" className="background-container">
            <div className="background-content" style={{ backgroundImage: `url(${background})` }} />
            <Col className="contacts-container">
                <Logo contact={true} />
                <div className="contacts-content">
                    <p className="contacts-title">Contatti</p>
                    <a href="mailto:muzzupapa.rocco@gmail.com"><p className="contacts">Email: muzzupapa.rocco@gmail.com</p></a>
                    <a href="tel:+39335-62-23-672"><p className="contacts">Cell: +39 335 62 23 672</p></a>  
                </div>
                <p className="powered">Powered by Giuseppe bianchino</p>
            </Col>
        </Row>
    )
}
