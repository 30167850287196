import React from 'react'

// IMPORT IMG
import pollice from '../../img/illustration/pollice.svg'

// IMPORT CSS
import './Logo.css'

export default function Logo(props) {
    return (
        <div className="logo-container" style={props.contact ? contactStyle : {}}>
            <p style={props.contact ? contactTextStyle : {}}>Rocco Muzzupapa</p>
            <img src={pollice}></img>
        </div>
    )
}

const contactStyle = {
    borderBottom: "1px solid #A2AEBB",
    paddingBottom: "5px",
}

const contactTextStyle = {
    color: "white"
}


