import React from 'react'
import Company from './Company'


//#region IMPORT COMPANIES LOGO
import rovatti from '../../img/loghi/rovatti 1.png'
import gf from '../../img/loghi/George-Fischer 1.png'
import idrobios from '../../img/loghi/idrobios 1.png'
import lucania from '../../img/loghi/lucania-resine 1.png'
import glong from '../../img/loghi/glong.jpg'
import dfm from '../../img/loghi/dfm.png'


//#endregion


// IMPORT CSS
import './Companies.css'




export default function Companies() {

    return (
        <div id="aziende" className="companies-container">
            <p className="companies-title">Alcune delle mie aziende partner</p>
            <Company
                alignment="left"
                color="#1C3144"
                logo={rovatti}
                text="Rovatti Pompe è partner dei propri clienti per rispondere con la massima rapidità ed efficienza alle dinamiche evoluzioni del mercato.La vastissima gamma di pompe centrifughe, la consolidata esperienza acquisita sul campo ed il continuo perfezionamento della qualità produttiva riassumono le potenzialità che l'azienda mette a disposizione dei propri clienti. Il valore aggiunto di ogni collaboratore completa infine la forza motrice dell'intero gruppo Rovatti."
                link="https://www.rovatti.it/"
                textColor="white"
                title="Rovatti Pompe"
            />
            <Company
                alignment="right"
                color="white"
                logo={gf}
                text="GF Piping Systems sviluppa, produce e commercializza una gamma completa di sistemi di tubazioni e relativi componenti, in una varietà di materiali utilizzati in tutto il mondo per il trasporto di acqua, gas e fluidi aggressivi. La fornitura dei prodotti, siano essi raccordi, valvole, strumenti di misura e di controllo, tubi o macchine per la saldatura, è sempre veloce, flessibile e affidabile."
                link="https://www.gfps.com/country_IT/it.html"
                textColor="#1C3144"
                title="Georg Fischer"
            />
            <Company
                alignment="left"
                color="#1C3144"
                logo={idrobios}
                text="Idrobios rappresenta ad oggi una realtà nota in tutto il territorio nazionale ed internazionale. Grazie ad una collaudata e duratura esperienza, è in grado di interpretare e soddisfare ogni esigenza del cliente, fornendo tutti gli strumenti necessari, dalla progettazione iniziale fino alla realizzazione dell'impianto."
                link="http://www.idrobios.com/"
                textColor="white"
                title="Idrobios"
            />
            <Company
                alignment="right"
                color="white"
                logo={lucania}
                text="Lucania Resine con sede nella città di Potenza, è un’azienda giovane e dinamica del System Group specializzata nella produzione di tubazioni in materie plastiche, in particolare tubi per fognature, acquedotti, irrigazione, gasdotti, cavidotti e drenaggi. La vasta gamma di prodotti è garantita da procedure e processi che rispecchiano gli standard qualitativi UNI EN ISO 9001:2008, certificazione IIP e marchi BVQI , IIP e IMQ."
                link="https://tubi.net/aziende/lucania-resine/#"
                textColor="#1C3144"
                title="Lucania Resine"
            />
            <Company
                alignment="left"
                color="#1C3144"
                logo={glong}
                text="GLONG ELECTRIC (NINGDE) CO. LTD. è uno dei maggiori produttori di motori e pompe d'acqua in Cina. L'azienda si estende su una superficie di 60mila metri quadrati, possiede una produzione avanzata e perfette apparecchiature di collaudo che garantiscono prodotti di alta qualità. I prodotti sono molto richiesti nei nostri mercati nazionali e d'oltremare."
                link="http://www.glong-motor.com/"
                textColor="white"
                title="Glong"
            />
            <Company
                alignment="right"
                color="white"
                logo={dfm}
                text="DFM ITALIA è specializzata nel commercio di
                materiali di qualità per la realizzazione di piscine
                pubbliche e private, propone materiali prodotti
                esclusivamente in Europa per garantire la qualità
                degli stessi.
                La gamma di questi ultimi offre soluzioni a 360°
                per la realizzazione di piscine complete e di grande
                estetica e qualità.
                DFM ITALIA è alla continua ricerca di innovazioni
                che migliorino il comfort e l’utilizzo tecnologico
                dei componenti, supportando l’installatore verso
                l’obiettivo primario della soddisfazione del Cliente
                finale.s"
                link="https://www.dfmitaliasrl.com/"
                textColor="#1C3144"
                title="DFM Italia"
            />
        </div>
    )
}
