import React from 'react'
import { Link } from 'react-scroll'
import Logo from '../logo/Logo'

// IMPORT CSS
import './Hero.css'

export default function Hero(props) {
    return (
        <div className="hero-container">
            <div className="hero-content">
                <p className="title">Rappresentanze termoidraulica e acquedottistica</p>
                {/* <p className="description">Da sempre alla ricerca di aziende <span>leader</span> del settore per dare il massimo della <span>qualità</span> ed <span>affidabilità</span> ai miei clienti!</p> */}
                <p className="description">Marzo 1990 ha inizio la mia avventura nel settore delle rappresentanze
                inizialmente con piccole aziende settore irrigazione e irrorazione, con le quali ho potuto maturare un’esperienza positiva, facendomi crescere in quello che poi sarebbe divenuto il mio lavoro. Con il passare del tempo ho avuto la fortuna di incontrare aziende, con cui a tutt’oggi collaboro, di primaria importanza, sia italiane che straniere, riuscendo così a dar vita ad un pacchetto di prodotti di altissima qualità e notevole rilevanza.
                Oggi la mia attività principale spazia in diversi settori, della termoidraulica, acquedottistica e depurazione, con le mie rappresentanze riesco a coprire una gran numero di prodotti utili a questi settori, la mia enorme esperienza unità alla forza delle aziende, che ho l’onore di rappresentare, fanno si che la mia clientela abbia risposte e soluzioni adeguate alle proprie necessità.
                Svolgo questo lavoro sempre a disposizione della mia clientela dando loro la possibilità di una vasta scelta di prodotti di altissima qualità e innovazione.
                </p>
                <div className="button">
                    <Link to="aziende" smooth={true} ><p>Scopri i miei partner</p></Link>
                </div>
            </div>
        </div>
    )
}
